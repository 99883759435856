//import bugsnag from 'bugsnag-js';

import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { navigate } from '@reach/router';

import { handleResponse } from '../api/utils';
import { silentAuth } from '../api/auth'

const serverURL = process.env.GATSBY_SWSM_ENDPOINT;
const ctx = 'primary';
const retryInterval = 300;
const retryAttempts = 5;

let accessToken, idToken, idTokenExpiry, ctxSetState;

export const onMount = (auth) => ({ setContextState }) => {

  //const bugsnagClient = bugsnag('8cec7ef3cea01b409e9591e373593f4b');

  auth.checkSession();

  ctxSetState = setContextState;
  const authenticated = accessToken != undefined;
  //ctxSetState(ctx, { authenticated, bugsnagClient });
  ctxSetState(ctx, { authenticated, });
};

export const onUnmount = ({ state }) => {
  ctxSetState = undefined;
};

export const onIdToken = (token, expiry) => {
  idToken = token;
  idTokenExpiry = expiry;
  if (ctxSetState) {
    ctxSetState(ctx, { idToken, idTokenExpiry });
  }
}

export const onLogout = () => {
  idToken = undefined;
  idTokenExpiry = undefined;
  accessToken = undefined;
}

export const errorLink = onError(({networkError, forward, operation }) => {
  if (networkError) {
    const { statusCode } = networkError;

    if (statusCode == "401") {
      accessToken = undefined;
      return forward(operation);
    } else if (statusCode == "500") {
      console.log('NE ', networkError.toString());
      navigate('/app/oops', { state: {error: networkError.toString() }});
    }

  }
});

const timer = (ms) => (new Promise(res => setTimeout(res, ms)));

export const refreshLink = setContext(async request => {
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer: ${accessToken}`
      }
    };
  }

  for (var i = 0; i < retryAttempts; i++) {

    const now = new Date().getTime() / 1000;
    const valid = (idTokenExpiry != undefined) && (now < idTokenExpiry);

    //console.log('Vaild: ', valid);

    if (valid && idToken) {
      const { access_token, error } = await bootstrapRefresh(idToken);
      if (access_token) {
        accessToken = access_token;

        //console.log('CSS: ', ctxSetState);

        ctxSetState(ctx, { authenticated: true });
        return {
          headers: {
            Authorization: `Bearer: ${access_token}`
          }
        };
      } else {
        ctxSetState(ctx, { bootstrapAuth: true });
        navigate('/app/join');
      }
    } else {
      await timer(retryInterval);
    }
  }

  return {
    headers: {}
  };

});

const bootstrapRefresh = (idToken) => {
  let payload = {};

  let request = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer: ${idToken}`,
    }
  }

  return fetch(`${serverURL}/bootstrap/access/refresh`, request)
  .then(handleResponse)
  .catch((error) => ({ error }));
};
