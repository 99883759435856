import React from 'react';

import './src/fonts/source-sans-pro/source-sans-pro.css';

import { Provider } from 'constate';
import Modal from 'react-modal';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { ApolloProvider } from 'react-apollo';

import Auth from './src/app/api/auth';

import { refreshLink, errorLink, onIdToken, onLogout, onMount, onUnmount } from './src/app/net/refresh';

const serverURL = process.env.GATSBY_SWSM_ENDPOINT;

Modal.setAppElement('body');

export const wrapRootElement = ({ element }) => {

  const auth = new Auth({onIdToken, onLogout});

  const httpLink = new HttpLink({
    uri: `${serverURL}/api`,
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 800,
      max: 400,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });

  const client = new ApolloClient({
    //link: ApolloLink.from([errorLink, refreshLink, retryLink, httpLink]),
    link: ApolloLink.from([errorLink, refreshLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const is = { primary: { auth, client }};
  return (
    <ApolloProvider client={client}>
      <Provider initialState={is} onMount={onMount(auth)} onUnmount={onUnmount}>
        {element}
      </Provider>
    </ApolloProvider>
  )
}
