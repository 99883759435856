import auth0 from 'auth0-js';
import { navigate } from '@reach/router';

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: process.env.GATSBY_CUSTOM_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
    redirectUri: `${process.env.GATSBY_SITE_URL}/auth/callback/`,
    responseType: 'token id_token',
    scope: 'openid',
  });

  mgmt = new auth0.WebAuth({
    domain: process.env.GATSBY_CUSTOM_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
    redirectUri: `${process.env.GATSBY_SITE_URL}/auth/callback/`,
    scope: 'openid',
    responseType: 'token id_token'
  });

  constructor({onIdToken, onLogout}) {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.onAuthResult = this.onAuthResult.bind(this);
    this.checkSession = this.checkSession.bind(this);
    
    this.onIdToken = onIdToken;
    this.onLogout = onLogout;
  }

  checkSession(destination) {

    // TODO replace with proper nonces
    const options = {
      nonce: Math.random().toString().replace('0.', ''),
      state: Math.random().toString().replace('0.', ''),
    };

    this.mgmt.checkSession(options, (err, authResult) => {
      if (!err){
        this.onAuthResult(authResult, destination);
      } else {
        // bugsnag??
        //console.warn('checkSession: ', err);
      }
    });
  }

  login(redirectUri) {
    if (redirectUri) {
      this.auth0.authorize({redirectUri});
    } else {
      this.auth0.authorize(); 
    }
  }

  handleAuthentication(destination) {

    this.auth0.parseHash((err, authResult) => {

      if (err){
        // TODO Bugsnag?
        //console.warn('Handle auth error: ', err);
      } else {
        this.onAuthResult(authResult, destination);
      }
    });
  }

  async onAuthResult(authResult, destination) {
    if (authResult && authResult.idTokenPayload && authResult.idToken) {
      
      const { idToken, idTokenPayload : { exp } } = authResult
      this.onIdToken(idToken, exp);
      
      if (destination) {
        await navigate(destination);
      }
    }
  }

  logout() {
    this.onLogout();
    document.location.href = `https://${process.env.GATSBY_CUSTOM_DOMAIN}/v2/logout?returnTo=${process.env.GATSBY_SITE_URL}`;
  }

}