// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-discover-js": () => import("./../src/templates/discover.js" /* webpackChunkName: "component---src-templates-discover-js" */),
  "component---src-templates-using-js": () => import("./../src/templates/using.js" /* webpackChunkName: "component---src-templates-using-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-billing-js": () => import("./../src/pages/app/billing.js" /* webpackChunkName: "component---src-pages-app-billing-js" */),
  "component---src-pages-app-cancel-js": () => import("./../src/pages/app/cancel.js" /* webpackChunkName: "component---src-pages-app-cancel-js" */),
  "component---src-pages-app-collect-js": () => import("./../src/pages/app/collect.js" /* webpackChunkName: "component---src-pages-app-collect-js" */),
  "component---src-pages-app-dashboard-js": () => import("./../src/pages/app/dashboard.js" /* webpackChunkName: "component---src-pages-app-dashboard-js" */),
  "component---src-pages-app-join-js": () => import("./../src/pages/app/join.js" /* webpackChunkName: "component---src-pages-app-join-js" */),
  "component---src-pages-app-oops-js": () => import("./../src/pages/app/oops.js" /* webpackChunkName: "component---src-pages-app-oops-js" */),
  "component---src-pages-auth-callback-js": () => import("./../src/pages/auth/callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-billing-js": () => import("./../src/pages/billing.js" /* webpackChunkName: "component---src-pages-billing-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-discover-js": () => import("./../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-flow-js": () => import("./../src/pages/flow.js" /* webpackChunkName: "component---src-pages-flow-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-trial-js": () => import("./../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */),
  "component---src-pages-using-js": () => import("./../src/pages/using.js" /* webpackChunkName: "component---src-pages-using-js" */),
  "component---src-pages-widgets-panel-js": () => import("./../src/pages/widgets/panel.js" /* webpackChunkName: "component---src-pages-widgets-panel-js" */),
  "component---src-pages-widgets-social-js": () => import("./../src/pages/widgets/social.js" /* webpackChunkName: "component---src-pages-widgets-social-js" */)
}

